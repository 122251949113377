import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { OverlayProps, Video } from "@components/VideoCarousel/types";
import { VideoControls } from "@components/VideoCarousel/PlayerControls";
import Player from "@components/VideoCarousel/Player";
import { Spinner } from "@radix-ui/themes";
import styles from "./Carousel.module.scss";
import { isMobile } from "react-device-detect";

type Props<T extends Video> = {
  isActive: boolean;
  video: T;
  index: number;
  onSelect: () => void;
  onNext: () => void;
  onPrev: () => void;
  hasNext: boolean;
  hasPrev: boolean;
  videoOverlay?: OverlayProps<T>;
  autoAdvanceDelay?: number;
  hideControlsDelay?: number;
};

export default function PlayerSlide<T extends Video>({
  isActive,
  video,
  index,
  onSelect,
  onNext,
  onPrev,
  hasNext,
  hasPrev,
  videoOverlay,
  autoAdvanceDelay,
}: Props<T>) {
  const playerRef = useRef<ReactPlayer>(null);
  const OverlayComponent = videoOverlay;
  const [videoHasCompleted, setVideoHasCompleted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [autoAdvancing, setAutoAdvancing] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setAutoAdvancing(false);

    if (isMobile) {
      setIsPlaying(false);
    } else {
      setIsPlaying(isActive);
    }

    // reset video when it becomes active
    if (isActive) {
      setVideoHasCompleted(false);
      playerRef.current?.seekTo(0);
    }
  }, [isActive]);

  useEffect(() => {
    if (autoAdvancing && autoAdvanceDelay !== undefined) {
      const timeout = setTimeout(onNext, autoAdvanceDelay * 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [autoAdvancing, autoAdvanceDelay, onNext]);

  // useEffect(() => {
  //   let timeout: NodeJS.Timeout;
  //   if (
  //     videoHasCompleted &&
  //     !isPlaying &&
  //     autoAdvanceDelay !== undefined &&
  //     isActive
  //   ) {
  //     timeout = setTimeout(onNext, autoAdvanceDelay * 1000);
  //   }
  //   return () => {
  //     if (timeout) {
  //       clearTimeout(timeout);
  //     }
  //   };
  // }, [autoAdvanceDelay, videoHasCompleted, isPlaying, isActive]);

  const handleVideoClick = () => {
    if (isActive) {
      if (videoHasCompleted) {
        // restarts the video
        setIsPlaying(true);
        setVideoHasCompleted(false);
        setAutoAdvancing(false);
      } else {
        setIsPlaying((prev) => !prev);
      }
    }
  };

  const handleOnEnded = () => {
    setVideoHasCompleted(true);

    // only auto-advance if the video is currently playing (not if the user skips to the end while paused)
    if (isPlaying) {
      setAutoAdvancing(true);
    }

    setIsPlaying(false);
    playerRef.current?.seekTo(0);
  };

  return (
    <>
      <div className={styles["player-wrapper"]}>
        {isActive && (
          <>
            <button
              className={`${styles["carousel-button"]} ${styles["back"]}`}
              onClick={onPrev}
              disabled={!hasPrev}
            />
            <button
              className={`${styles["carousel-button"]} ${styles["next"]}`}
              onClick={onNext}
              disabled={!hasNext}
            />
          </>
        )}
        <div
          className={styles["player-container"]}
          onClick={handleVideoClick}
          onMouseEnter={() => {
            if (!isTouchDevice()) {
              setIsHovered(true);
            }
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
        >
          {isActive && (
            <>
              <VideoControls
                isVisible={(!isPlaying || isHovered) && !videoHasCompleted}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                playerRef={playerRef}
              />
              {OverlayComponent && (
                <div className={styles["video-overlay-wrapper"]}>
                  <OverlayComponent
                    video={video}
                    index={index}
                    stopPlayback={() => {
                      setIsPlaying(false);
                      setAutoAdvancing(false);
                    }}
                    videoHasCompleted={videoHasCompleted}
                  />
                </div>
              )}
            </>
          )}

          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            className={`${styles["video-thumbnail"]} ${!isActive && styles["inactive"]}`}
            alt={""}
            src={video.thumbnailUrl}
            onClick={onSelect}
          />

          <Player
            url={video.streamUrl}
            isPlaying={isPlaying}
            onBuffer={() => setIsBuffering(true)}
            onBufferEnd={() => setIsBuffering(false)}
            onEnded={handleOnEnded}
            playerRef={playerRef}
            onError={(error) => {
              // eslint-disable-next-line no-console
              console.error("Player error: ", error, video.streamUrl);
              // Check for not allowed error, and set isPlaying to false in that case
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (error.name === "NotAllowedError") {
                setIsPlaying(false);
              }
            }}
          />

          {isActive && isBuffering && (
            <div className={styles["loading-overlay"]}>
              <Spinner size={"3"} style={{ color: "white" }} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function isTouchDevice() {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
}
