import { CarouselProps, Video } from "./types";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { EmblaCarouselType, EmblaEventType } from "embla-carousel";
import PlayerSlide from "@components/VideoCarousel/PlayerSlide";
import "@components/VideoCarousel/Carousel.module.scss";
import { isMobile } from "react-device-detect";
import styles from "./Carousel.module.scss";
import { clamp } from "@radix-ui/number";

export default function Carousel<T extends Video>({
  videos,
  selectedIndex,
  setSelectedIndex,
  videoOverlay,
  autoAdvanceDelay,
  hideControlsDelay,
}: CarouselProps<T>) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    containScroll: false,
    duration: 20,
  });

  const tweenNodes = useRef<HTMLElement[]>([]);

  const setTweenNodes = useCallback((emblaApi: EmblaCarouselType): void => {
    tweenNodes.current = emblaApi.slideNodes();
  }, []);

  const tweener = useCallback(
    (emblaApi: EmblaCarouselType, eventName?: EmblaEventType) => {
      const engine = emblaApi.internalEngine();
      const scrollProgress = emblaApi.scrollProgress();
      const slidesInView = emblaApi.slidesInView();
      const isScrollEvent = eventName === "scroll";
      const numSlides = emblaApi.scrollSnapList().length;

      emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
        const diffToTarget = scrollSnap - scrollProgress;
        const slidesInSnap = engine.slideRegistry[snapIndex];

        slidesInSnap.forEach((slideIndex) => {
          if (isScrollEvent && !slidesInView.includes(slideIndex)) return;
          const tweenValue = diffToTarget * (numSlides - 1);
          const tweenNode = tweenNodes.current[slideIndex];
          const tweenNodeWidth = tweenNode.offsetWidth;

          if (tweenNodeWidth < window.innerWidth) {
            // Only apply scaling if the slide is smaller than the viewport. This should make things a bit smoother on mobile
            // scale down to 30%
            const scale = 1 - 0.7 * clamp(Math.abs(tweenValue), [0, 1]);
            const betweenFactor = 210;
            const fromCenterFactor = 180;
            const translateX =
              -tweenValue * betweenFactor +
              clamp(tweenValue, [-1, 1]) * fromCenterFactor;
            tweenNode.style.transform = `scale(${scale}) translateX(${translateX}%)`;
          }

          const transparencyNext = 1;
          const transparencyPrev = 0.5;
          const transparency =
            tweenValue > 0 ? transparencyPrev : transparencyNext;
          const opacity =
            1 - transparency * clamp(Math.abs(tweenValue), [0, 1]);
          tweenNode.style.opacity = `${opacity}`;
        });
      });
    },
    [],
  );

  // triggered as soon as a slide is selected
  const onSlideSelected = useCallback(
    (emblaApi: EmblaCarouselType) => {
      setSelectedIndex(emblaApi.selectedScrollSnap());
    },
    [setSelectedIndex],
  );

  const [initialScrolled, setInitialScrolled] = useState(false);

  useEffect(() => {
    if (!emblaApi) return;

    setTweenNodes(emblaApi);
    tweener(emblaApi);

    if (!initialScrolled) {
      emblaApi.scrollTo(selectedIndex || 0, true);
      setInitialScrolled(true);
    }

    emblaApi
      .on("reInit", setTweenNodes)
      .on("reInit", tweener)
      .on("scroll", tweener)
      .on("select", onSlideSelected);
  }, [
    emblaApi,
    onSlideSelected,
    tweener,
    setTweenNodes,
    initialScrolled,
    selectedIndex,
  ]);

  // hide carousel unless a slide is selected
  if (selectedIndex == null) {
    return null;
  }

  return (
    <div
      className={`${styles["carousel-overlay"]} ${isMobile ? "mobile" : ""}`}
    >
      <button
        className={styles["close-button"]}
        onClick={() => setSelectedIndex(null)}
      />

      <div className={styles.embla} ref={emblaRef}>
        <div className={styles["videos-container"]}>
          {videos.map((video, index) => (
            <PlayerSlide
              key={`${index}-${video.streamUrl}`}
              isActive={index === selectedIndex}
              video={video}
              index={index}
              onSelect={() => emblaApi?.scrollTo(index)}
              onNext={() => emblaApi?.scrollNext()}
              onPrev={() => emblaApi?.scrollPrev()}
              hasNext={emblaApi?.canScrollNext() ?? false}
              hasPrev={emblaApi?.canScrollPrev() ?? false}
              videoOverlay={videoOverlay}
              autoAdvanceDelay={autoAdvanceDelay}
              hideControlsDelay={hideControlsDelay}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
