import React from "react";
import { CaretLeftIcon, CaretRightIcon } from "@radix-ui/react-icons";
import { IconButton } from "@radix-ui/themes";

function ScrollLeftButton({
  onClick,
  canScrollLeft,
  className,
}: {
  onClick: () => void;
  canScrollLeft: boolean;
  className: string;
}) {
  return (
    <ScrollButton
      onClick={() => onClick()}
      canScroll={canScrollLeft}
      ariaLabel={"Scroll left"}
      className={className}
    >
      <CaretLeftIcon width={24} height={24} />
    </ScrollButton>
  );
}

function ScrollRightButton({
  onClick,
  canScrollRight,
  className,
}: {
  onClick: () => void;
  canScrollRight: boolean;
  className: string;
}) {
  return (
    <ScrollButton
      onClick={() => onClick()}
      canScroll={canScrollRight}
      ariaLabel={"Scroll right"}
      className={className}
    >
      <CaretRightIcon />
    </ScrollButton>
  );
}

function ScrollButton(props: {
  onClick: () => void;
  canScroll: boolean;
  ariaLabel: string;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <IconButton
      onClick={props.onClick}
      variant={"surface"}
      color={"mint"}
      size={"3"}
      radius={"full"}
      aria-label={props.ariaLabel}
      disabled={!props.canScroll}
      className={props.className}
    >
      {props.children}
    </IconButton>
  );
}

export { ScrollLeftButton, ScrollRightButton };
