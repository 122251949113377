import { ForwardButton, PauseButton, PlayButton, RewindButton } from "./icons";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import styles from "./Carousel.module.scss";

type VideoControlsProps = {
  isVisible: boolean;
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  playerRef: React.RefObject<ReactPlayer>;
};

export function VideoControls({
  isVisible,
  isPlaying,
  setIsPlaying,
  playerRef,
}: VideoControlsProps) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!isVisible) return;

    let animationFrameId: number | null;

    const animateProgress = () => {
      const currentTime = playerRef.current?.getCurrentTime() ?? 0;
      const duration = playerRef.current?.getDuration() ?? 0;
      const newProgress = currentTime / duration;
      setProgress(newProgress);

      animationFrameId = requestAnimationFrame(animateProgress);
    };

    animateProgress();

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [isVisible, playerRef]);

  function updateProgress(newProgress: number) {
    if (!playerRef.current) return;

    const targetTime = newProgress * playerRef.current.getDuration();
    playerRef.current?.seekTo(targetTime);
  }

  const handleRewind = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      const targetTime = Math.max(0, currentTime - 10);
      playerRef.current.seekTo(targetTime);
    }
  };

  const handleForward = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      const duration = playerRef.current.getDuration();
      const targetTime = Math.min(duration, currentTime + 10);
      playerRef.current.seekTo(targetTime);
    }
  };

  return (
    <div className={styles["player-controls"]}>
      <div
        className={`${styles["player-controls-inner"]} ${isVisible ? styles["visible"] : ""}`}
      >
        {isPlaying ? (
          <PauseButton onClick={() => setIsPlaying(false)} />
        ) : (
          <PlayButton onClick={() => setIsPlaying(true)} />
        )}
        <div>
          <RewindButton onClick={handleRewind} />
          <ForwardButton onClick={handleForward} />
        </div>
        <Timeline progress={progress} setProgress={updateProgress} />
      </div>
    </div>
  );
}

type TimelineProps = {
  progress: number;
  setProgress: (progress: number) => void;
};
function Timeline({ progress, setProgress }: TimelineProps) {
  function handleClick(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();

    const timelineElement = event.currentTarget;
    const { left, width } = timelineElement.getBoundingClientRect();
    const clickPositionX = event.clientX - left;
    const progress = clickPositionX / width;

    setProgress(progress);
  }

  return (
    <div className="carousel-player-timeline" onClick={handleClick}>
      <div
        className="carousel-player-timeline-progress"
        style={{ width: `${progress * 100}%` }}
      />
    </div>
  );
}
