import { useEffect, useRef, useState } from "react";

const useScroller = () => {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const checkScrollButtons = () => {
    const scroller = scrollerRef.current;
    if (scroller) {
      const maxScrollLeft = scroller.scrollWidth - scroller.clientWidth;
      setCanScrollLeft(scroller.scrollLeft > 0);
      setCanScrollRight(scroller.scrollLeft < maxScrollLeft);
    }
  };

  useEffect(() => {
    const scroller = scrollerRef.current;
    if (scroller) {
      scroller.addEventListener("scroll", checkScrollButtons, {
        passive: true,
      });
      checkScrollButtons(); // Initial check on component mount
    }
    return () => {
      if (scroller) {
        scroller.removeEventListener("scroll", checkScrollButtons);
      }
    };
  }, []);

  const scroll = (direction: "left" | "right") => {
    // When direction is left, try to move the last item on the right to the beginning
    // When direction is right, try to move the first item on the left to the end
    const scroller = scrollerRef.current;
    if (scroller) {
      const containerWidth = scroller.clientWidth;
      const containerScrollLeft = scroller.scrollLeft;
      const style = window.getComputedStyle(scroller);
      const paddingLeft = parseInt(style.paddingLeft, 10);
      const paddingRight = parseInt(style.paddingRight, 10);
      if (direction === "right") {
        const targetScrollLeft = containerScrollLeft + containerWidth;
        for (let i = 0; i < scroller.children.length; i++) {
          const child = scroller.children[i] as HTMLElement;
          const childEnd = child.offsetLeft + child.offsetWidth;
          if (childEnd > targetScrollLeft) {
            scroller.scrollBy({
              left: child.offsetLeft - containerScrollLeft - paddingLeft,
              behavior: "smooth",
            });
            break;
          }
        }
      } else if (direction === "left") {
        for (let i = 0; i < scroller.children.length; i++) {
          const child = scroller.children[i] as HTMLElement;
          const childRight = child.offsetLeft + child.offsetWidth;
          if (childRight > containerScrollLeft) {
            scroller.scrollBy({
              left:
                childRight -
                containerScrollLeft -
                containerWidth +
                paddingRight,
              behavior: "smooth",
            });
            break;
          }
        }
      }
    }
  };

  return { scrollerRef, canScrollLeft, canScrollRight, scroll };
};

export default useScroller;
