import React, { CSSProperties } from "react";
import styles from "./responsiveHelpers.module.scss";
import { Slot } from "@radix-ui/themes";

interface NonMobilePortraitViewProps extends React.PropsWithChildren {
  style?: CSSProperties;
  className?: string;
  asChild?: boolean;
}

function NonMobilePortraitView({
  children,
  style,
  className = "", // Provide a default value to ensure backward compatibility
  asChild,
}: NonMobilePortraitViewProps) {
  // Combine the provided className with your mobileView style
  const combinedClassName = `${styles.nonMobilePortraitView} ${className}`;
  const Comp = asChild ? Slot : "div";
  return (
    <Comp className={combinedClassName} style={style}>
      {children}
    </Comp>
  );
}

export { NonMobilePortraitView };
