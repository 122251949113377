export const PlayButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <svg
      width="57"
      height="64"
      viewBox="0 0 57 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <path
        d="M11.1601 1.02255C6.21554 -1.86087 0 1.69641 0 7.40965V56.5904C0 62.3036 6.21566 65.8609 11.1601 62.9775L53.3265 38.3871C58.2245 35.5307 58.2245 28.4693 53.3265 25.6129L11.1601 1.02255Z"
        fill="white"
      />
    </svg>
  );
};

export const PauseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <svg
      width="57"
      height="64"
      viewBox="0 0 99 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <path
        d="M1.08993 5.46009C0 7.59921 0 10.3995 0 16V101C0 106.601 0 109.401 1.08993 111.54C2.04867 113.422 3.57847 114.951 5.46009 115.91C7.59921 117 10.3995 117 16 117H26C31.6005 117 34.4008 117 36.5399 115.91C38.4215 114.951 39.9513 113.422 40.9101 111.54C42 109.401 42 106.601 42 101V16C42 10.3995 42 7.59921 40.9101 5.46009C39.9513 3.57847 38.4215 2.04867 36.5399 1.08993C34.4008 0 31.6005 0 26 0H16C10.3995 0 7.59921 0 5.46009 1.08993C3.57847 2.04867 2.04867 3.57847 1.08993 5.46009Z"
        fill="white"
      />
      <path
        d="M58.0899 5.46009C57 7.59921 57 10.3995 57 16V101C57 106.601 57 109.401 58.0899 111.54C59.0487 113.422 60.5785 114.951 62.4601 115.91C64.5992 117 67.3995 117 73 117H83C88.6005 117 91.4008 117 93.5399 115.91C95.4215 114.951 96.9513 113.422 97.9101 111.54C99 109.401 99 106.601 99 101V16C99 10.3995 99 7.59921 97.9101 5.46009C96.9513 3.57847 95.4215 2.04867 93.5399 1.08993C91.4008 0 88.6005 0 83 0H73C67.3995 0 64.5992 0 62.4601 1.08993C60.5785 2.04867 59.0487 3.57847 58.0899 5.46009Z"
        fill="white"
      />
    </svg>
  );
};

export const RewindButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <svg
      width="49"
      height="54"
      viewBox="0 0 49 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <path
        d="M18.4887 6.43527C17.821 6.89846 17.821 7.88967 18.4887 8.35286L27.4653 14.5798C28.2346 15.1134 29.284 14.5602 29.284 13.621V10.0157C37.942 12.1668 44.3609 20.0234 44.3609 29.3862C44.3609 40.4061 35.4689 49.3394 24.5 49.3394C13.5311 49.3394 4.63905 40.4061 4.63905 29.3862C4.63905 22.6545 7.95506 16.7007 13.0459 13.0833C14.0918 12.3402 14.34 10.8859 13.6003 9.83518C12.8605 8.78444 11.413 8.53511 10.3671 9.27827C4.09966 13.7317 0 21.0779 0 29.3862C0 42.98 10.969 54 24.5 54C38.031 54 49 42.98 49 29.3862C49 17.4372 40.5247 7.47682 29.284 5.24149V1.16716C29.284 0.227934 28.2346 -0.325298 27.4653 0.20836L18.4887 6.43527Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.653 23.1889C26.6402 21.4533 28.269 19.9194 30.4438 19.9194C32.6185 19.9194 34.2474 21.4533 35.2345 23.1889C36.2498 24.9739 36.8225 27.3231 36.8225 29.8232C36.8225 32.3232 36.2498 34.6725 35.2345 36.4575C34.2474 38.193 32.6185 39.7269 30.4438 39.7269C28.269 39.7269 26.6402 38.193 25.653 36.4575C24.6378 34.6725 24.0651 32.3232 24.0651 29.8232C24.0651 27.3231 24.6378 24.9739 25.653 23.1889ZM28.6739 24.9231C28.0102 26.09 27.5444 27.8187 27.5444 29.8232C27.5444 31.8276 28.0102 33.5563 28.6739 34.7232C29.3658 35.9396 30.0565 36.2315 30.4438 36.2315C30.8311 36.2315 31.5218 35.9396 32.2137 34.7232C32.8774 33.5563 33.3432 31.8276 33.3432 29.8232C33.3432 27.8187 32.8774 26.09 32.2137 24.9231C31.5218 23.7067 30.8311 23.4149 30.4438 23.4149C30.0565 23.4149 29.3658 23.7067 28.6739 24.9231Z"
        fill="white"
      />
      <path
        d="M12.6417 23.4311C11.8499 23.9779 11.6494 25.066 12.1937 25.8614C12.738 26.6568 13.8211 26.8583 14.6128 26.3115L16.5266 24.9896L16.5266 37.3966C16.5266 38.3619 17.3055 39.1444 18.2663 39.1444C19.2271 39.1444 20.0059 38.3619 20.0059 37.3966V21.6671C20.0059 21.0181 19.6479 20.4225 19.0762 20.1204C18.5044 19.8182 17.8131 19.8592 17.2807 20.2269L12.6417 23.4311Z"
        fill="white"
      />
    </svg>
  );
};

export const ForwardButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <svg
      width="49"
      height="54"
      viewBox="0 0 49 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <path
        d="M30.5113 6.43527C31.179 6.89846 31.179 7.88967 30.5113 8.35286L21.5347 14.5798C20.7654 15.1134 19.716 14.5602 19.716 13.621V10.0157C11.058 12.1668 4.63905 20.0234 4.63905 29.3862C4.63905 40.4061 13.5311 49.3394 24.5 49.3394C35.4689 49.3394 44.3609 40.4061 44.3609 29.3862C44.3609 22.6545 41.0449 16.7007 35.9541 13.0833C34.9082 12.3402 34.66 10.8859 35.3997 9.83518C36.1395 8.78444 37.587 8.53511 38.6329 9.27827C44.9003 13.7317 49 21.0779 49 29.3862C49 42.98 38.031 54 24.5 54C10.969 54 0 42.98 0 29.3862C0 17.4372 8.47526 7.47682 19.716 5.24149V1.16716C19.716 0.227934 20.7654 -0.325298 21.5347 0.20836L30.5113 6.43527Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.653 23.1889C26.6402 21.4533 28.269 19.9194 30.4438 19.9194C32.6185 19.9194 34.2474 21.4533 35.2345 23.1889C36.2498 24.9739 36.8225 27.3231 36.8225 29.8232C36.8225 32.3232 36.2498 34.6725 35.2345 36.4575C34.2474 38.193 32.6185 39.7269 30.4438 39.7269C28.269 39.7269 26.6402 38.193 25.653 36.4575C24.6378 34.6725 24.0651 32.3232 24.0651 29.8232C24.0651 27.3231 24.6378 24.9739 25.653 23.1889ZM28.6739 24.9231C28.0102 26.09 27.5444 27.8187 27.5444 29.8232C27.5444 31.8276 28.0102 33.5563 28.6739 34.7232C29.3658 35.9396 30.0565 36.2315 30.4438 36.2315C30.8311 36.2315 31.5218 35.9396 32.2137 34.7232C32.8774 33.5563 33.3432 31.8276 33.3432 29.8232C33.3432 27.8187 32.8774 26.09 32.2137 24.9231C31.5218 23.7067 30.8311 23.4149 30.4438 23.4149C30.0565 23.4149 29.3658 23.7067 28.6739 24.9231Z"
        fill="white"
      />
      <path
        d="M12.6417 23.4311C11.8499 23.9779 11.6494 25.066 12.1937 25.8614C12.738 26.6568 13.8211 26.8583 14.6128 26.3115L16.5266 24.9896L16.5266 37.3966C16.5266 38.3619 17.3055 39.1444 18.2663 39.1444C19.2271 39.1444 20.0059 38.3619 20.0059 37.3966V21.6671C20.0059 21.0181 19.6479 20.4225 19.0762 20.1204C18.5044 19.8182 17.8131 19.8592 17.2807 20.2269L12.6417 23.4311Z"
        fill="white"
      />
    </svg>
  );
};
