/* It's Complicated example video type */
import { Video } from "@components/VideoCarousel/types";
import { Box, Flex } from "@radix-ui/themes";
import {
  SessionCity,
  SessionFormats,
} from "@components/Profile/Tags/ProfileTags";
import React from "react";
import { TextV2 } from "@/design-system/components/text/TextV2";
import ProfileAvatar from "@/design-system/components/Avatar/ProfileAvatar";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import Link from "next/link";
import { useTranslations } from "next-intl";
import styles from "./TherapistSpotlightVideoOverlay.module.scss";
import { ampli } from "@/analytics/ampli";

export type VideoCarouselVideo = {
  firstName: string;
  name: string;
  title: string;
  cityName: string;
  supportsInPersonTherapy: boolean;
  supportsVideoTherapy: boolean;
  supportsTextTherapy: boolean;
  pictureUrl?: string;
  partialUri: string;
  therapistUserId: string;
} & Video;

type Props = {
  video: VideoCarouselVideo;
  index: number;
  videoHasCompleted: boolean;
  stopPlayback: () => void;
};
export function TherapistSpotlightVideoOverlay({
  video,
  index,
  videoHasCompleted,
  stopPlayback,
}: Props) {
  const t = useTranslations("TherapistSpotlightVideos");
  return (
    <Flex direction={"column"}>
      <Flex align={"start"} style={{ padding: "20px" }}>
        <Flex
          direction={"column"}
          align={"start"}
          gap={"1"}
          pr={"8"} // avoid the close button
          className={styles.shadowedTextContainer}
          style={{ color: "var(--white)", minWidth: "0" }}
        >
          <TextV2
            textStyle={"Headline M"}
            align={"right"}
            wrap={"nowrap"}
            style={{ maxWidth: "100%" }}
            truncate
          >
            {video.name}
          </TextV2>
          <TextV2
            textStyle={"Body M"}
            align={"right"}
            wrap={"nowrap"}
            style={{ maxWidth: "100%" }}
            truncate
          >
            {video.title}
          </TextV2>
        </Flex>
      </Flex>
      <Link
        href={`/find-a-therapist${video.partialUri}`}
        target={"_blank"}
        onClick={(e) => {
          e.stopPropagation();
          stopPlayback();
          ampli.featuredTherapistVideoViewProfileClicked({
            index: index,
            therapist_user_id: video.therapistUserId,
            therapist_video_click_source: "bottom_overlay",
          });
        }}
        style={{ maxWidth: "100%" }}
      >
        <Flex
          direction={"row"}
          width={"100%"}
          justify={"between"}
          gap={"3"}
          style={{
            padding: "12px 20px 20px 20px",
            position: "absolute",
            bottom: 0,
            background: "rgba(55, 55, 54, 0.5)",
            justifyContent: "space-between",
            color: "white",
            fontWeight: 300,
          }}
        >
          {/* left side badges */}
          <Flex direction={"column-reverse"} align={"start"} gap={"4"}>
            <SessionFormats
              supportsInPersonTherapy={video.supportsInPersonTherapy}
              supportsVideoTherapy={video.supportsVideoTherapy}
              supportsTextTherapy={video.supportsTextTherapy}
            />
            <SessionCity name={video.cityName} />
          </Flex>
          <Flex direction={"column"} align={"center"} gap={"2"} justify={"end"}>
            <OutlinedProfileAvatar pictureUrl={video.pictureUrl} />
            <TextV2 textStyle={"Body S"}>{t("visit_text")}</TextV2>
          </Flex>

          {/* right side title name, title & profile picture (on desktop) */}
        </Flex>
      </Link>
      {videoHasCompleted && (
        <Flex
          justify={"center"}
          align={"center"}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "rgba(119, 119, 119, 0.5)",
          }}
        >
          {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
          <ButtonV2
            size={"4"}
            color={"mint"}
            onClick={(e) => {
              e.stopPropagation();
              stopPlayback();
              ampli.featuredTherapistVideoViewProfileClicked({
                index: index,
                therapist_user_id: video.therapistUserId,
                therapist_video_click_source: "finished_main_cta",
              });
            }}
            asChild
          >
            <Link
              href={`/find-a-therapist${video.partialUri}`}
              target={"_blank"}
            >
              {t("visit_cta", {
                therapist: video.firstName,
              })}
            </Link>
          </ButtonV2>
        </Flex>
      )}
    </Flex>
  );
}

function OutlinedProfileAvatar({
  pictureUrl,
  style,
}: {
  pictureUrl?: string;
  style?: React.CSSProperties;
}) {
  return (
    <Box
      style={{
        border: "3px solid var(--colorV2-light-green)",
        borderRadius: "50%",
        ...style,
      }}
    >
      <ProfileAvatar picture={pictureUrl} size={"4"} />
    </Box>
  );
}
